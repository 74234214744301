export const FirebaseConfig = {
	"projectId": "ecg-telnet",
	"appId": "1:860540330866:web:01fe1644c0d595ffe633eb",
	"databaseURL": "https://ecg-telnet.firebaseio.com",
	"storageBucket": "ecg-telnet.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyATVdM06ppI_ycHCSrFLz114fO57xAYqVA",
	"authDomain": "ecg-telnet.firebaseapp.com",
	"messagingSenderId": "860540330866",
	"measurementId": "G-TLR4H31HX4"
};